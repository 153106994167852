/* WhyUsPage.css */

.background-box {
  position: relative;
  background-color: #d8ebf0;
  width: 100%;
  height: 100vh; /* Adjust the height as needed */

  /* Create a diagonal split using a pseudo-element */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%; /* Adjust to control the split angle */
    height: 100%;
    background-color: white; /* Color for the left side */
    z-index: -1; /* Place behind the content */
    transform-origin: top right;
    transform: skewX(-45deg); /* Adjust the skew angle */
  }
}

.diagonal-partition {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-45deg, white 50%, #d8ebf0 50%);
}

span:hover {
  transform: scale(1.02);
  transition: all 1.5s;
}
