/* Default font size */
.heading-in-small-box {
  font-size: 1.1rem; /* Adjust this value as needed */
  font-weight: bolder;
}

/* Media query for screens with a width of 1299px or more */
@media screen and (max-width: 1299px) {
  .heading-in-small-box {
    font-size: 0.9rem; /* Adjust this value for the desired font size */
  }
}
/* Working */
@media screen and (max-width: 768px) {
  .fixW {
    align-items: "center";
  }
}
