.heading {
  background: linear-gradient(to right, rgb(67, 124, 205), rgb(69, 214, 202));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.heading-2 {
  background: linear-gradient(to right, #ffd179, white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo:nth-child(1) {
  transition-delay: 100ms;
}
.logo:nth-child(2) {
  transition-delay: 150ms;
}
.logo:nth-child(3) {
  transition-delay: 200ms;
}
.logo:nth-child(4) {
  transition-delay: 250ms;
}
