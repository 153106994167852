/* ImageSlider.css */

/* Slider container to cover the whole screen */
.image-slider {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.image-container {
  position: relative;
  width: 100%;
  /* height: 80vh; */
  padding-bottom: 45.3%; /* 16:9 aspect ratio (for widescreen) */
  margin-bottom: -6px;
}

.slider-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust the opacity for the tint */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Style for the construction text */
.construction-text {
  color: #fff; /* Text color */
  font-size: 24px; /* Adjust font size */
  text-align: center;
  padding: 20px;
}

.slick-dots {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

/* Increase the size of individual dots */
.slick-dots li button::before {
  content: "•"; /* Use a larger dot character */
  font-size: 18px; /* Adjust the font size to increase dot size */
  color: #fff; /* Customize the dot color */
  opacity: 0.8; /* Adjust the opacity if needed */
  transition: all 0.3s; /* Add a transition for a smoother effect */
}

/* Style the active dot */
.slick-dots li.slick-active button::before {
  opacity: 0.9; /* Increase opacity for the active dot */
  font-weight: bold; /* Optionally, make the active dot bold */
}
