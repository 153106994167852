.slide-in {
  animation: slideIn 1s ease-in-out forwards;
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
}
@keyframes slideIn {
  to {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fade-in 1s ease-out;
  animation-fill-mode: forwards; /* This keeps the final state after animation */
}
@keyframes zoom-in {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.zoom-in {
  animation: zoom-in 2s ease-out;
  animation-fill-mode: forwards;
}
@keyframes reveal-from-top {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.reveal-from-top {
  animation: reveal-from-top 0.5s ease-out;
  animation-fill-mode: forwards;
}
.background-image img {
  /* Apply the zoom-in effect based on scroll position */
  transform: scale(1 + calc(var(--scroll-percent) * 0.1));
  transform-origin: center top;
  transition: transform 0.3s ease-in-out;
}

.slide-in2 {
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.slide-in2.active {
  opacity: 1;
  transform: translateX(0);
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(
      -100%
    ); /* Adjust the value to the direction you want to slide */
    opacity: 0;
  }
}

/* Apply the animation to an element with the slide-out class */
.slide-out {
  animation: slideOut 0.5s ease-out forwards; /* Adjust the duration and easing as needed */
}

@media (max-width: 1299px) {
  .head {
    font-size: small;
  }
}

.hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 1s;
  width: 100%;
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}

.wave-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #0074e4; /* Adjust this to your desired blue color */
}

.wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px; /* Adjust the wave's height as needed */
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    #ffffff 50%,
    #0074e4 50%,
    #0074e4 100%
  );
}

.wave::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px; /* Adjust the wave's height as needed */
  background: linear-gradient(
    180deg,
    #0074e4 0%,
    #0074e4 50%,
    #ffffff 50%,
    #ffffff 100%
  );
  transform: rotate(180deg);
}
.wave-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #0074e4, white);
  z-index: -1;
}
