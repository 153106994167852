.heading-container {
  /* Specify your desired styling for the heading */
  font-size: 40px;
  letter-spacing: 0.5rem;
  font-family: "Montserrat";
  /* background-color: black; */
  margin-right: "40px";
}

/* Add a media query to hide the heading on small screens */
/* @media (max-width: 768px) {
  .heading-container {
    display: none;
  }
} */
@media (max-width: 1104px) {
  .heading-container {
    display: none;
  }
}
.active-button {
  border-radius: 5px;

  background-color: #33d6ff;
}
.navbar {
  background-color: #00ffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

/* Logo styles */
.logo {
  width: 200px;
}

.button-container {
  position: relative;
  display: inline-block;
}

/* Dropdown container */
.dropdown {
  border-radius: 5px;
  width: 200px;
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Style the dropdown links */
.dropdown a {
  display: block;
  padding: 10px 20px;
  color: #006078;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Show the dropdown on hover */
.button-container:hover .dropdown {
  display: block;
}

/* Hover effect for the dropdown links */
.dropdown a:hover {
  background-color: #006078;
  color: #fff;
}
.mobile-menu {
  position: fixed;
  top: 112px;
  left: 0;
  right: 0;
  background-color: white;
  display: none;
  transition: max-height 0.7s ease-out, padding 0.7s ease-out;
  overflow: hidden;
}

.mobile-menu.active {
  display: block;
  max-height: 500px; /* Adjust the height as needed */
  padding: 16px; /* Adjust the padding as needed */
  z-index: 2;
}

.navbar-button {
  font-size: 16px; /* Default font size */
  padding: 10px 20px; /* Default padding */
}

/* Media query for screens wider than 1340px */
@media (min-width: 1341px) {
  .navbar-button {
    font-size: 16px; /* Keep the same font size */
    padding: 10px 20px; /* Keep the same padding */
  }
}

/* Media query for screens up to 1340px */
@media (max-width: 1340px) {
  .navbar-button {
    font-size: 14px; /* Adjust font size for smaller screens */
    padding: 8px 16px; /* Adjust padding for smaller screens */
  }
}
