/* .slick-prev,
.slick-next {
  width: 100px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.slick-prev:hover,
.slick-next:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.slick-prev:before,
.slick-next:before {
  font-size: 24px;
  color: white;
} */

/* your-arrow-styles.css */

/* Custom styles for the arrow container */
.custom-next-arrow,
.custom-prev-arrow {
  width: 50px;
  height: 50px;
  background-color: #33d6ff; /* Background color for the arrows */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

/* Styles for the arrow icons */
.custom-arrow-icon {
  color: white; /* Icon color */
  font-size: 24px; /* Adjust the icon size as needed */
}

/* Hover effect for arrows */
.custom-next-arrow:hover,
.custom-prev-arrow:hover {
  background-color: #0099cc; /* Change background color on hover */
}

/* Adjust the spacing between the arrows and the slides if needed */
/* .custom-next-arrow {
  margin-right: 10px;
}
.custom-prev-arrow {
  margin-left: 10px;
} */

.slick-prev {
  left: 5px; /* Adjust the left position as needed */
  z-index: 1; /* Ensure the arrow is above the carousel content */
  /* Add other styles like background color, size, etc. */
}

/* Style for the next (right) arrow */
.slick-next {
  right: 35px; /* Adjust the right position as needed */
  z-index: 1; /* Ensure the arrow is above the carousel content */
  /* Add other styles like background color, size, etc. */
}

/* Customize the arrow icon (if needed) */
.slick-prev:before,
.slick-next:before {
  color: #fff; /* Change the arrow icon color */
  font-size: 50px; /* Change the arrow icon size */
}
/* Default styles for videos */
.video-container {
  /* Add your default video styles here */
}

/* Styles for mobile screens */
@media screen and (max-width: 767px) {
  .video-container {
    /* Adjust styles for mobile screens */
    width: 100%; /* Make videos full width on mobile */
    max-width: 100%; /* Ensure videos don't exceed screen width */
    margin-bottom: 20px; /* Add spacing between videos */
  }
}

/* Styles for desktop screens */
@media screen and (min-width: 768px) {
  .video-container {
    /* Adjust styles for desktop screens */
    width: 33.33%; /* Set videos to 50% width on desktop for two videos per slide */
    max-width: none; /* Allow videos to be wider on desktop */
    margin-bottom: 0; /* Remove spacing between videos */
    float: left; /* Float videos to the left for side-by-side layout */
    box-sizing: border-box; /* Include padding and border in width calculation */
    padding: 0 10px; /* Add padding to create space between videos */
  }
}
